<template>
    <OrderName :record="record" />
</template>

<script>
import OrderName from '@apps/Orders/components/OrdersList/OrderName.vue'
export default {
    components: {
        OrderName
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    }
}
</script>